import { JSEncrypt } from './jsencrypt';
import md5 from '../js-md5/md5';
// 验签：请求参数中值不为空的参数，加上时间戳进行拼接，然后按照字母从a到z排序，最后加上两边验签的秘钥串形成的加密串并转成大写

// 公钥
const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDIZQyKO17dRHjEjzE0+DuD99BXz80ZO9yLAH9OesmuDbptJd3CaDUR0LW38+CfSy3jpY5eVdwuzu90UxfewYtSTIInvmWmid6ygFYB/ou/m0SITCiS4q/BGzevXu/BgES6f2z2wjd5tgp03JY2SQvdSvd60pw6LyLKyNFUyjmmWQIDAQAB';
const key = 'wHsWB3pTQiQYQXHzMvVX';
const publicKeyStart = '------BEGIN PUBLIC KEY-----';
const ublicKeyEnd = '-----END PUBLTC KEY-----';

//  加密  
export function RSAencryptedData (data) {
  // 新建JSEncrypt对象  
  const encryptor = new JSEncrypt();
  // 设置公钥  
  encryptor.setPublicKey(publicKeyStart + publicKey + ublicKeyEnd);
  // 加密数据  
  return encryptor.encrypt(data);
}

// 无序对象排序
function objectSort (param) {
  const newObj = {}; //有序键名对象
  Object.keys(param).sort().map(key => {
    newObj[key] = param[key];
  });
  return newObj;
}


// 转为参数字符串
function strParam (param, timestamp) {
  const obj = objectSort({ ...param, timestamp });
  let txt = '';
  Object.keys(obj).forEach(key => {
    txt += `${key}=${obj[key]}&`;
  });
  txt += `key=${key}`;
  // console.log(txt)
  return md5(txt).toUpperCase(); // 转为大写
}

// 参数加密串
export function EncryptedParam (param, timestamp) {
  return strParam(param, timestamp);
}